<script lang="ts" setup>
import { getUUID, setUUID } from '~/shared/utils/uuid';
import { useRoute } from '#vue-router';

onMounted(() => {
  const uuid = getUUID();
  setUUID(uuid);
});

const config = useRuntimeConfig();

const route = useRoute();

const logoJsonld = computed(() => {
  return {
    '@context': 'http://www.schema.org',
    '@type': 'Organization',
    'name': 'Kitekat',
    'url': `https://www.kitekat.ru${route.fullPath}`,
    'logo': 'https://www.kitekat.ru/_nuxt/images/logo.svg',
  };
});
useHead({
  script: [
    {
      'src': `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`,
      'data-domain-script': config.public.cookieDomain,
    },
    {
      innerHTML: JSON.stringify(logoJsonld.value),
      type: 'application/ld+json',
    },
  ],

});
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
