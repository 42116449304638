export enum BreadcrumbNames {
  Main = 'Главная',
  Rations = 'Корма KITEKAT™',
  Wet = 'Влажные корма KITEKAT™',
  Dry = 'Сухие корма KITEKAT™',
  Faq = 'Часто задаваемые вопросы',
  Shops = 'Где купить',
  Articles = 'Статьи о кошках',
  Experts = 'Эксперты',
  Stories = 'Ваши истории',
}

export enum BreadcrumbLinks {
  Main = '/',
  Rations = '/korma',
  Wet = '/korma/vlazhnye',
  Dry = '/korma/suhie',
  Faq = '/faq',
  Shops = '/gde-kupit',
  Articles = '/articles',
  Experts = '/experts',
  Stories = '/vashi-istorii',
}

export enum CookieNames {
  Uuid = '_kuuid',
}

export enum LikeOrigin {
  Articles = '/articles/',
  ArticlesDetail = '/articles/slug/',
  ArticlesDetailExpert = '/articles/slug/:expert',
  ExpertsDetail = '/experts/slug',
}
