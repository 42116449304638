<script setup lang="ts"></script>

<template>
  <div class='container'>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $breakpoint-md) {
    width: 92%;
    max-width: 1600px;
  }
}
</style>
