
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexdP7zTFRl7aZq2Pc08cwkTh9_Xhz5atMbl890jb4bLIEMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as index5dMHWws_45hSqaY_45b_avLzusRo34ibhvseGOwRLE4hyfQMeta } from "/usr/src/app/pages/faq/index.vue?macro=true";
import { default as index9TtNHx0JShN7SxOfHK69wGYQ_45GUnkZQJFP1tQQV0IVsMeta } from "/usr/src/app/pages/korma/index.vue?macro=true";
import { default as indexZZT0rrQqEYxSRIx7_q_TagPVCOizV2dA5wfOaXHHXvQMeta } from "/usr/src/app/pages/experts/index.vue?macro=true";
import { default as indexiDQLD4ORuEDVL8zLZpZtmu9m7mlooValU3QVEOCUL0sMeta } from "/usr/src/app/pages/articles/index.vue?macro=true";
import { default as _91slug_93U_0FDTucyYlD1PjntTomK9s7TaDklBtnReeZW7V3oisMeta } from "/usr/src/app/pages/experts/[slug].vue?macro=true";
import { default as _91slug_93INoBLLXjsp1kPDqOvvlDzuZ9n58HuR_GWykxwFKWQFwMeta } from "/usr/src/app/pages/articles/[slug].vue?macro=true";
import { default as indexd_45KfW1YJ7Kyfo_Y5G_gpolUpAjv5Lft9czWJKY432UEMeta } from "/usr/src/app/pages/gde-kupit/index.vue?macro=true";
import { default as _articleyfrg_45K5cY69Ss29UfIrHB4RhkTukAjDanUqAcu3Jm84Meta } from "/usr/src/app/pages/articles/_article.vue?macro=true";
import { default as indexRo0DeSQTYYI3AJb_45rlBIQb_OWeC6RfePyOT85bQG96QMeta } from "/usr/src/app/pages/korma/suhie/index.vue?macro=true";
import { default as _91slug_93HbmLFErRRU4YLcQVwqtm8DYP6AmGWNI8_PtXDdQcxfwMeta } from "/usr/src/app/pages/korma/suhie/[slug].vue?macro=true";
import { default as indexzTfsAfUvXM0kzBgyrmAgNVsdhkx44_GaeynMftxbBagMeta } from "/usr/src/app/pages/vashi-istorii/index.vue?macro=true";
import { default as index9U00h4zCM2sRLAEjO8YWn_dv8k5_qN1Vq3MApazKrhsMeta } from "/usr/src/app/pages/korma/vlazhnye/index.vue?macro=true";
import { default as _91slug_93aVj85hf3AwupvwZFp7gjSYiEqE2BgaRandhC_kpKJsAMeta } from "/usr/src/app/pages/korma/vlazhnye/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/usr/src/app/pages/faq/index.vue")
  },
  {
    name: "korma",
    path: "/korma",
    component: () => import("/usr/src/app/pages/korma/index.vue")
  },
  {
    name: "experts",
    path: "/experts",
    component: () => import("/usr/src/app/pages/experts/index.vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/usr/src/app/pages/articles/index.vue")
  },
  {
    name: "experts-slug",
    path: "/experts/:slug()",
    component: () => import("/usr/src/app/pages/experts/[slug].vue")
  },
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    component: () => import("/usr/src/app/pages/articles/[slug].vue")
  },
  {
    name: "gde-kupit",
    path: "/gde-kupit",
    component: () => import("/usr/src/app/pages/gde-kupit/index.vue")
  },
  {
    name: "articles-_article",
    path: "/articles/_article",
    component: () => import("/usr/src/app/pages/articles/_article.vue")
  },
  {
    name: "korma-suhie",
    path: "/korma/suhie",
    component: () => import("/usr/src/app/pages/korma/suhie/index.vue")
  },
  {
    name: "korma-suhie-slug",
    path: "/korma/suhie/:slug()",
    component: () => import("/usr/src/app/pages/korma/suhie/[slug].vue")
  },
  {
    name: "vashi-istorii",
    path: "/vashi-istorii",
    component: () => import("/usr/src/app/pages/vashi-istorii/index.vue")
  },
  {
    name: "korma-vlazhnye",
    path: "/korma/vlazhnye",
    component: () => import("/usr/src/app/pages/korma/vlazhnye/index.vue")
  },
  {
    name: "korma-vlazhnye-slug",
    path: "/korma/vlazhnye/:slug()",
    component: () => import("/usr/src/app/pages/korma/vlazhnye/[slug].vue")
  }
]