function createHydrationPromise(cleanup) {
  let resolvePromise = () => {
  };
  const promise = new Promise((resolve) => {
    resolvePromise = () => {
      cleanup();
      resolve();
    };
  });
  const onResolvedPromise = (cb) => {
    void promise.then(cb);
  };
  return {
    promise,
    resolvePromise,
    onResolvedPromise
  };
}
export {
  createHydrationPromise as default
};
