import { getCurrentInstance } from "vue";
function useHydrateWhenIdle({
  willPerformHydration,
  hydrate,
  onCleanup
}, timeout = 2e3) {
  if (!willPerformHydration) {
    return;
  }
  if (!getCurrentInstance()) {
    throw new Error(
      "useHydrateWhenIdle must be called from the setup or lifecycle hook methods."
    );
  }
  if (!("requestIdleCallback" in window)) {
    hydrate();
    return;
  }
  const idleId = requestIdleCallback(
    () => {
      hydrate();
    },
    { timeout }
  );
  onCleanup(() => {
    cancelIdleCallback(idleId);
  });
}
export {
  useHydrateWhenIdle as default
};
