import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from '~/shared/utils/cookie';
import { CookieNames } from '~/consts';

const getUUID = () => {
  const cookieUUID = getCookie(CookieNames.Uuid);

  if (cookieUUID) return cookieUUID;
  return uuidv4();
};

const setUUID = (uuid: string) => {
  const expiresDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const expires = expiresDate.toUTCString();
  setCookie(CookieNames.Uuid, uuid, { expires });
};

export {
  getUUID,
  setUUID,
};
