import { getCurrentInstance, onMounted } from "vue";
import createHydrationObserver from "../utils/create-hydration-observer.mjs";
import getRootElements from "../utils/get-root-elements.mjs";
function useHydrateWhenVisible({
  willPerformHydration,
  hydrate,
  onCleanup
}, observerOptions) {
  if (!willPerformHydration) {
    return;
  }
  const instance = getCurrentInstance();
  if (!instance || instance.isMounted) {
    throw new Error(
      "useHydrateWhenVisible must be called from the setup method."
    );
  }
  const { supported, observer } = createHydrationObserver(observerOptions);
  if (!supported) {
    hydrate();
    return;
  }
  onMounted(() => {
    const els = getRootElements(instance);
    els.forEach((target) => {
      target.hydrate = hydrate;
      observer.observe(target);
    });
    onCleanup(() => {
      els.forEach((target) => {
        delete target.hydrate;
        observer.unobserve(target);
      });
    });
  });
}
export {
  useHydrateWhenVisible as default
};
