import traverseChildren from "./traverse-children.mjs";
function isAsyncWrapper(vnode) {
  var _a, _b;
  return ((_a = vnode.type) == null ? void 0 : _a.__asyncLoader) && ((_b = vnode.type) == null ? void 0 : _b.name) === "AsyncComponentWrapper";
}
function waitForAsyncComponents({ subTree }, cb) {
  const promises = [];
  traverseChildren(subTree, (vnode) => {
    if (isAsyncWrapper(vnode)) {
      promises.push(vnode.type.__asyncLoader());
    }
  });
  if (promises.length > 0) {
    void Promise.all(promises).then(cb);
    return;
  }
  cb();
}
export {
  waitForAsyncComponents as default
};
