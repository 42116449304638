function createHydrationCleanup() {
  let cleanups = [];
  const onCleanup = (cb) => {
    cleanups.push(cb);
  };
  const cleanup = () => {
    cleanups = cleanups.filter((fn) => {
      fn();
      return false;
    });
  };
  return { cleanup, onCleanup };
}
export {
  createHydrationCleanup as default
};
