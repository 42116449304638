<script setup lang="ts">
import type { NuxtError } from '#app';

import Button from '~/components/UI/Button.vue';
import Container from '~/components/UI/Container.vue';

const props = defineProps({
  error: Object as () => NuxtError,
  default: () => {},
});

const getErrorCode = computed(() => {
  return props?.error?.statusCode;
});

const getErrorText = computed(() => {
  return props?.error?.statusCode === 404 ? 'Страница не найдена' : 'Произошла ошибка';
});
</script>

<template>
  <div class='error-page'>
    <div class='error-page__labyrinth' />

    <Container class='error-page__container'>
      <picture>
        <source
          media='(min-width: 768px)'
          srcset='/assets/images/404/cat.webp'
          type='image/webp'
          width='226'
          height='283'
        />
        <img
          src='/assets/images/404/cat-mobile.webp'
          alt=''
          class='error-page__cat'
          loading='lazy'
          width='202'
          height='253'
        />
      </picture>

      <h2 class='error-page__title'>
        <span class='error-page__code'>
          {{ getErrorCode }}
        </span>
        <span class='error-page__text'>
          {{ getErrorText }}
        </span>
      </h2>

      <div class='error-page__links'>
        <Button
          to='/'
          :style-type="'white-black'"
          class='error-page__button'
        >
          На главную
        </Button>
        <Button
          to='/korma'
          :style-type="'white-black'"
          class='error-page__button'
        >
          Все рационы
        </Button>
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
.error-page {
  position: relative;
  padding-bottom: 2rem;
  overflow: hidden;
}

.error-page__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.error-page__labyrinth {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background: url('/assets/images/404/labyrinth-mobile.webp') center no-repeat;
  width: 23rem;
  height: 34rem;

  @media (min-width: $breakpoint-md) {
    background-image: url('/assets/images/404/labyrinth.webp');
    background-size: contain;
    width: 100vw;
    max-width: 78.375rem;
    aspect-ratio: 12/8;
    transform: translate(-50%, -50%);
  }
}

.error-page__cat {
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media (min-width: $breakpoint-lg) {
    margin-top: 0;

    @media (orientation: landscape) {
      max-height: 30vh;
      width: auto;
    }
  }
}

.error-page__title {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  font-family: var(--font-dom);
  line-height: 1;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2rem;
}

.error-page__code {
  font-size: 5rem;

  @media (min-width: $breakpoint-lg) {
    font-size: 11rem;
  }
}

.error-page__text {
  font-size: 2.25rem;

  @media (min-width: $breakpoint-lg) {
    font-size: 2.625rem;
  }
}

.error-page__links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  column-gap: 1rem;

  @media (min-width: $breakpoint-lg) {
    column-gap: 3rem;
  }
}

.error-page__button {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.15);
    }
  }
}
</style>
