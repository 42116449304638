import { defineComponent, computed, toRef, markRaw } from "vue";
import useLazyHydration from "../composables/useLazyHydration.mjs";
import useHydrateWhenIdle from "../composables/useHydrateWhenIdle.mjs";
import useHydrateOnInteraction from "../composables/useHydrateOnInteraction.mjs";
import useHydrateWhenTriggered from "../composables/useHydrateWhenTriggered.mjs";
import useHydrateWhenVisible from "../composables/useHydrateWhenVisible.mjs";
const normalizeSlot = (slotContent) => slotContent.length === 1 ? slotContent[0] : slotContent;
const LazyHydrationWrapper = defineComponent({
  name: "LazyHydrationWrapper",
  inheritAttrs: false,
  suspensible: false,
  props: {
    whenIdle: {
      default: false,
      type: [Boolean, Number]
    },
    whenVisible: {
      default: false,
      type: [Boolean, Object]
    },
    onInteraction: {
      default: false,
      type: [Array, Boolean, String]
    },
    whenTriggered: {
      default: void 0,
      type: [Boolean, Object]
    }
  },
  emits: ["hydrated"],
  setup(props, { slots, emit }) {
    const result = useLazyHydration();
    if (!result.willPerformHydration) {
      return () => normalizeSlot(slots.default());
    }
    result.onHydrated(() => emit("hydrated"));
    if (props.whenIdle) {
      useHydrateWhenIdle(
        result,
        props.whenIdle !== true ? props.whenIdle : void 0
      );
    }
    if (props.whenVisible) {
      useHydrateWhenVisible(
        result,
        props.whenVisible !== true ? props.whenVisible : void 0
      );
    }
    if (props.onInteraction) {
      let events;
      if (props.onInteraction !== true) {
        events = computed(
          () => Array.isArray(props.onInteraction) ? props.onInteraction : [props.onInteraction]
        ).value;
      }
      useHydrateOnInteraction(result, events);
    }
    if (props.whenTriggered !== void 0) {
      useHydrateWhenTriggered(result, toRef(props, "whenTriggered"));
    }
    return () => normalizeSlot(slots.default());
  }
});
const LazyHydrationWrapper$1 = markRaw(LazyHydrationWrapper);
export {
  LazyHydrationWrapper$1 as default
};
