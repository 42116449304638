const observers = /* @__PURE__ */ new Map();
function createHydrationObserver(options) {
  const supported = typeof IntersectionObserver !== "undefined";
  if (!supported) {
    return { supported };
  }
  const optionKey = JSON.stringify(options);
  if (observers.has(optionKey)) {
    return { supported, observer: observers.get(optionKey) };
  }
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        const isIntersecting = entry.isIntersecting || entry.intersectionRatio > 0;
        if (!isIntersecting || !entry.target.hydrate) {
          return;
        }
        entry.target.hydrate();
      }
    );
  }, options);
  observers.set(optionKey, observer);
  return { supported, observer };
}
export {
  createHydrationObserver as default
};
