function ensureParentHasSubTreeEl(parent) {
  if (!parent || !parent.subTree) {
    return;
  }
  const parentSubTreeEl = parent.subTree.el;
  const parentOnUpdatedHook = parent.u;
  if (parent.u === null) {
    parent.u = [];
  }
  parent.u.push(() => {
    if (parent.subTree.el === null) {
      parent.subTree.el = parentSubTreeEl;
    }
    parent.u = parentOnUpdatedHook;
  });
}
export {
  ensureParentHasSubTreeEl as default
};
